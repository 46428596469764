import React from "react"
import { graphql } from "gatsby"
import Map from "../images/contact_bg_map.png"
import Layout from "../components/layout"

const ContactPage = ({data}) => {

  return (
    <Layout>
      <div className="flex justify-between flex-wrap">
        <section
          className="contact-page w-1/4"
          dangerouslySetInnerHTML={{ __html: data.content.childMarkdownRemark.html }}></section>
        <section className="w-3/4">
          <img src={Map} alt="MS Forks Locations Map"/>
        </section>
        </div>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
query($lang: String) {

  content: file(sourceInstanceName: {eq: $lang}, name: {eq: "contact"}) {
    id
      childMarkdownRemark {
        html
      }
  }

}
`;